<template>
  <div class="mobile-valet">
    <div class="mobile-valet-container">
      <div class="mobile-valet-text-container">
        <h4>
          If you’re not able to come to us that is not a problem as you can arrange for one of our mobile valet and detailing specialists to come to you at home or at work.
        </h4>
        <h1>
          IF IT’S YOUR VEHICLES’
          <br/>
          "TIME TO SHINE"
        </h1>
        <router-link to="Contact" class="contact-button-cont">
          <div class="contact-button" >Contact Us</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  components: {
  }
})
</script>

  

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
.mobile-valet {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;

  .mobile-valet-container {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    height: auto;

    em {
      color: #f2ba5c;
      font-weight: bold;
      font-style: normal;
    }

    @include breakpoint-mobile {
    }

    @include breakpoint-small-mobile {
    }

    @include breakpoint-tablet {
    }

    .mobile-valet-text-container {
      width: 80vw;
      z-index: 4;
      margin: 4rem auto;
      text-align: center;

      @include breakpoint-small-mobile {
        margin-bottom: 0em;
        margin-left: 0em;
        width: 80vw;
      }

      @include breakpoint-mobile {
        margin-top: 30vh;
        margin-bottom: 0em;
        margin-top: 0vh;
        margin-left: 10vw;
        width: 82vw;
      }

      @include breakpoint-tablet {
        width: 100vw;
        margin-left: 3em;
        height: fit-content;
      }

      @include breakpoint-large-tablet {
        margin-left: 4em;
        width: 55rem;
        p{
          font-size: 1.2rem;          
        }
      }

      @include breakpoint-small-laptop {
        margin-bottom: 0em;
      }

      @include breakpoint-laptop{
        margin-bottom: 0em;
      }

      @include breakpoint-large-laptop{
        margin-bottom: 0em;
      }

      h1,h2,h3,h4,h5,h6 {
        font-weight: 400;
      }

      h1 {
        text-align: center;
        margin: 4rem auto 2rem;
      }

      h4 {
        width: 45vw;
        text-align: center;
        margin: 0rem auto 2rem;
      }

      .h3,h4,h5,h6 {
        font-family: "inter", sans-serif;
      }

      p {
        margin-top: 2rem;
        font-weight: 400;
        width: 40vw;
        margin: 2rem auto;
      }

      em {
        color: $main-color;
        font-weight: 100;
      }
      
      .link-button-container {
        margin-top: 3em;
        margin-bottom: 3em;
      }
    }
  }
}

.contact-button-cont {
  margin: 1rem auto;
  display: grid;
}

.contact-button {
  margin: 15px auto;
  width: 15vw;
  text-align: center;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  border-radius: 20px;
  padding: 15px 20px;
  border: 0.2rem solid #000;

  @include breakpoint-small-mobile {
    width: 50vw;
  }

  @include breakpoint-mobile {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-tablet {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-large-tablet { 
    font-size: 1.4rem;
    width: 30vw;
  }

  @include breakpoint-small-laptop { 
    width: 20vw;
  }

  @include breakpoint-laptop { 
    width: 20vw;
  }

  @include breakpoint-extra-large {
    font-size: 2rem;
  }
}

.contact-button:hover {
  background-color: $main-color-hover;
  color: $secondary-color;
  border: 0.2rem solid $main-color;
}
</style>