<template>
  <div class="service-packages-mobile-valeting-page-cont">      
      <!-- Project Navigation services-packages-mobile-valeting-page -->
      <section class="image-text floors">
        <div class="services-page-mobile-valeting-text-container">
          <h1 class="services-page-mobile-valeting-title text-center">
            Mobile Valeting
          </h1>
          <p class="text-center" >
            If you’re not able to come to us that is not a problem as you can arrange for one of our mobile valet and detailing specialists to come to you at home or at work.
          </p>
          <h6 style="font-weight: 100;font-kerning: none;
    letter-spacing: 0.01rem;">
            Additional charge for anything over five mile radius. Contact us for price.
          </h6>
        </div>
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="services-packages-mobile-valeting-page-container services-packages-mobile-valeting-page-grid">

              <!-- Bronze -->
              <div class="services-packages-mobile-valeting-page small">
                <div class="services-packages-mobile-valeting-page-inner services-packages-mobile-valeting-page-grid">
                  <div class="services-packages-mobile-valeting-page-text services-packages-mobile-valeting-page-grid">
                    <h2 class="services-packages-mobile-valeting-page-title">Bronze</h2>
                    <ul class="services-list-grid">
                      <li>
                        Exterior Wash & Wax
                      </li>
                      <li>
                        Exterior Dry
                      </li>
                      <li>
                        Tyre Shine
                      </li>
                    </ul>
                    <div class="mb-32">
                      <div class="side-list ">
                        <p>
                          Car
                          <br>
                          4x4/SUV
                          <br>
                          Van                
                        </p>
                        <p class="brand-colour">
                          £25
                          <br>
                          £30
                          <br>
                          £35
                        </p>
                      </div>
                    </div>

                    <div>
                      <h6 class="services-packages-mobile-valeting-page-title" style="text-align: left;">
                        Extras
                      </h6>
                      <p >
                        Exterior snow foam wash
                      </p>
                      <p class="brand-colour text-left">
                        Additional £5
                      </p>
                      <br>
                      <p class="text-left">
                        Traffic Film remover (TFR)
                      </p>
                      <p class="brand-colour text-left">
                        Additional £5
                      </p>
                    </div>
                    
                  </div> 
                </div>
              </div>

              <!-- Silver -->
              <div class="services-packages-mobile-valeting-page">
                <div class="services-packages-mobile-valeting-page-inner services-packages-mobile-valeting-page-grid">
                  <div class="services-packages-mobile-valeting-page-text services-packages-mobile-valeting-page-grid">
                    <h2 class="services-packages-mobile-valeting-page-title">Silver</h2>
                    <ul class="services-list-grid">
                      <li>
                        Exterior Wash & Wax
                      </li>
                      <li>
                        Exterior Dry
                      </li>
                      <li>
                        Tyre Shine
                      </li>
                      <li>
                        Traffic Film Remover
                      </li>
                      <li>
                        Interior Vacuum
                      </li>
                      <li>
                        Upholstery Clean
                      </li>
                    </ul>
                    <div class="mb-32">
                      <div class="side-list">
                        <p>
                          Car
                          <br>
                          4x4/SUV
                          <br>
                          Van                
                        </p>
                        <p class="brand-colour">
                          £45
                          <br>
                          £55
                          <br>
                          £60
                        </p>                      
                      </div>
                      <p class="brand-colour">
                        Free Air Fresher
                      </p>                      
                    </div>
                    
                    <div>
                      <h6 class="services-packages-mobile-valeting-page-title" style="text-align: left;">
                        Extras
                      </h6>
                      <p >
                        Exterior snow foam wash
                      </p>
                      <p class="brand-colour text-left">
                        Additional £5
                      </p>
                      <br>
                      <p class="text-left">
                        Pet Hair Removal
                      </p>
                      <p class="brand-colour text-left">
                        Additional £15
                      </p>
                    </div>
                    
                  </div> 
                </div>
              </div>

              <!-- Gold -->
              <div class="services-packages-mobile-valeting-page">
                <div class="services-packages-mobile-valeting-page-inner services-packages-mobile-valeting-page-grid">
                  <div class="services-packages-mobile-valeting-page-text services-packages-mobile-valeting-page-grid">
                    <h2 class="services-packages-mobile-valeting-page-title">Gold</h2>
                    <ul class="services-list-grid">
                      <li>
                        Exterior Wash & Wax
                      </li>
                      <li>
                        Exterior Snow Foam Wash
                      </li>
                      <li>
                        Exterior Dry
                      </li>
                      <li>
                        Tyre Shine
                      </li>
                      <li>
                        Traffic Film Remover
                      </li>
                      <li>
                        Interior Vacuum
                      </li>
                      <li>
                        Seat Clean
                      </li>
                      <li>
                        Upholstery Clean
                      </li>
                    </ul>
                    <div class="mb-32">
                      <div class="side-list">
                        <p>
                          Car
                          <br>
                          4x4/SUV
                          <br>
                          Van                
                        </p>
                        <p class="brand-colour">
                          £60
                          <br>
                          £70
                          <br>
                          £75
                        </p>                      
                      </div>
                      <p class="brand-colour">
                        Free Air Fresher
                      </p>                      
                    </div>
                    
                    <div>
                      <h6 class="services-packages-mobile-valeting-page-title" style="text-align: left;">
                        Extras
                      </h6>
                      <p class="text-left">
                        Pet Hair Removal
                      </p>
                      <p class="brand-colour text-left">
                        Additional £10
                      </p>
                    </div>
                    
                  </div> 
                </div>
              </div>

            </div>

            <div class="services-packages-mobile-valeting-page-container services-packages-mobile-valeting-page-grid horizontal">
              <!-- Platinum -->
              <div class="services-packages-mobile-valeting-page horizontal">
                <div class="services-packages-mobile-valeting-page-inner services-packages-mobile-valeting-page-grid horizontal">
                  <div class="services-packages-mobile-valeting-page-text services-packages-mobile-valeting-page-grid horizontal">
                    <h2 class="services-packages-mobile-valeting-page-title">Platinum</h2>
                    <div class="side-list horizontal">
                      <ul class="services-list-grid">
                        <li>
                          Exterior Wash & Wax
                        </li>
                        <li>
                          Exterior Snow Foam Wash
                        </li>                        
                        <li>
                          Traffic Film Remover
                        </li>
                        <li>
                          PLastics & Tyres Dresses
                        </li>
                        <li>
                          Interior Vacuum
                        </li>
                      </ul>
                      <ul class="services-list-grid">
                        <li>
                          Seats Cleaned
                        </li>
                        <li>
                          Body Work Decontamination
                        </li>
                        <li>
                          Body Work Sealant
                        </li>
                        <li>
                          Hand Polish
                        </li>
                      </ul>
                    </div>
                    <div class="mb-32">
                      <div class="side-list">
                        <p class="brand-colour">
                          Free Air Fresher
                        </p> 
                        <p class="brand-colour">
                          Free Pet Hair Removal
                        </p>                     
                      </div>
                                           
                    </div>
                    
                    <div>
                      <p class="text-left">
                        Priced on a quote basis
                      </p>
                    </div>
                    <router-link to="Contact" class="contact-button-cont">
                      <div class="contact-button" >Contact Us</div>
                    </router-link>
                  </div>                    
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <div class="mt-64">
          <h2 class="text-center" style="font-weight: 100;">
            Bookings Available
          </h2>
        </div>
        <router-link to="Contact" class="contact-button-cont">
          <div class="contact-button" >Book Now</div>
        </router-link>
      </section>
    </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
.service-packages-mobile-valeting-page-cont {
  background-image: linear-gradient(to top right, #060606f5, #0000009c), url("../img/carshining.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  height: fit-content;
  padding-bottom: 4rem;
}

.services-page-mobile-valeting-text-container {
    font-weight: 100;
    width: 60vw;
    text-align: center;
    margin: auto;
    margin-bottom: 3rem;
}

.services-page-mobile-valeting-title {
  margin: 0rem auto 6rem;
  padding-top: 4rem;
}

.services-packages-mobile-valeting-page-container{
  display: grid;
  grid-gap: 1rem;
  min-width: 80vw;
  max-width: 80vw;
  margin: 0 auto;

  &.services-packages-mobile-valeting-page-grid {
    grid-template-columns: repeat(3, 1fr);
    min-width: 60vw;
    max-width: 60vw;

    @include breakpoint-small-mobile {
      min-width: 80vw;
      max-width: 80vw;
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint-mobile{
      min-width: 80vw;
      max-width: 80vw;
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint-tablet{
      min-width: 80vw;
      max-width: 80vw;
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint-large-tablet {
      min-width: 80vw;
      max-width: 80vw;
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint-small-laptop{
      min-width: 80vw;
      max-width: 80vw;
    }

    &.horizontal {
      grid-template-columns: repeat(1, 1fr);
      min-width: 60vw;
      max-width: 60vw;
    }
  }

  .side-list {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    margin: 1rem auto 0rem;

    &.horizontal {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      -moz-column-gap: 1rem;
      column-gap: 1rem;
      margin: 1rem auto 0rem;
      -moz-column-gap: 4rem;
      column-gap: 4rem;
      align-content: flex-start;

    }

    p {
      color: black!important;
      text-align: left;
      font-weight: bold;
      &.brand-colour {
        color: $main-color!important;
        text-shadow: 1px 1px 0px #000000ad;
      }
    }
  }

  

  .services-packages-mobile-valeting-page {

    
  }

  .services-packages-mobile-valeting-page-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;  
    overflow: hidden;
    height: 15rem;
    width: auto;

    @include breakpoint-small-mobile {
      flex-direction: column;
    }

    @include breakpoint-mobile {
      flex-direction: column;
    }

    @include breakpoint-extra-large {
      width: auto;
    }

    &.services-packages-mobile-valeting-page-grid {
      min-height: 45rem;
      max-height: 45rem;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      @include breakpoint-small-mobile {
        height: fit-content;
        min-height: 20rem;
        max-height: 30rem;
      }

      @include breakpoint-mobile{
        height: fit-content;
        min-height: 20rem;
        max-height: 30rem;
      }

      @include breakpoint-tablet{
        height: fit-content;
        min-height: 20rem;
        max-height: 30rem;
      }

      @include breakpoint-large-tablet {
        height: fit-content;
        min-height: 20rem;
        max-height: 30rem;
      }

      &.horizontal {
        flex-direction: row;
        min-height: 35rem;
        max-height: 35rem;
      }
    }
  }

  .services-packages-mobile-valeting-page-title {
    font-weight: 400;
    color: $main-color!important;
  }

  .services-list-grid {
    li {
      list-style-type: disc;
      text-align: left;
      color: black!important;
    }

    @include breakpoint-small-mobile {
      padding: 0rem 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      column-width: 27rem;
      column-gap: 2rem;
    }

    @include breakpoint-mobile{
      padding: 0rem 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      column-width: 27rem;
      column-gap: 2rem;
    }

    @include breakpoint-tablet{
      padding: 0rem 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      column-width: 27rem;
      column-gap: 2rem;
    }

    @include breakpoint-large-tablet {
      padding: 0rem 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      column-width: 27rem;
      column-gap: 2rem;
    }

    @include breakpoint-small-laptop{
      padding: 0rem 1rem;
    }

    @include breakpoint-laptop{
      padding: 0rem 1rem;
    }  

    @include breakpoint-large-laptop{
      padding: 0rem 1rem;
    }

    @include breakpoint-desktop{
      padding: 0rem 2rem;
    }

    @include breakpoint-extra-large {
    } 
  }

  .services-packages-mobile-valeting-page img{
    width: auto;
    height: -webkit-fill-available;
    object-fit: fill;
  }

  .services-packages-mobile-valeting-page-text {
    width: 100%;
    text-align: center;
    padding: 0.7rem;
    background: #ffffffd1;
    color: black!important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-height: 40rem;
    max-height: 40rem;
    border-radius: 35px;
    border: 1rem solid #5394a1;

    @include breakpoint-small-mobile {
      height: fit-content;
      min-height: 20rem;
      max-height: 30rem;
    }

    @include breakpoint-mobile{
      height: fit-content;
      min-height: 20rem;
      max-height: 30rem;
    }

    @include breakpoint-tablet{
      height: fit-content;
      min-height: 20rem;
      max-height: 30rem;
    }

    @include breakpoint-large-tablet {
      height: fit-content;
      min-height: 20rem;
      max-height: 30rem;
    }

    @include breakpoint-small-laptop{
      height: fit-content;
      min-height: 40rem;
      max-height: 40rem;
    }

    @include breakpoint-laptop{
      height: fit-content;
      min-height: 45rem;
      max-height: 45rem;
    }  

    @include breakpoint-large-laptop{
      height: fit-content;
      min-height: 40rem;
      max-height: 40rem;
    }

    @include breakpoint-desktop{
      height: fit-content;
      min-height: 40rem;
      max-height: 40rem;
    }

    @include breakpoint-extra-large {
      height: fit-content;
      min-height: 40rem;
      max-height: 40rem;
    }

    &.horizontal {
        min-height: 33rem;
        max-height: 33rem;
      }

    &.services-packages-mobile-valeting-page-grid {
      justify-content: flex-start;
    }

    h1,h2,h3,h4,h5,h6 {
      font-size: 1.6rem;
    }

    h4,h5,h6 {
      font-size: 1.2rem;
    }

    h1,h2,h3,h4,h5,h6,p,ul {
      margin: 0.1rem 0rem!important;
      color: $main-color-hover;

      @include breakpoint-large-tablet {
        font-size: 1.4rem;
      }
    }

    p {
      display: -webkit-box;
      font-size: 0.8rem;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      @include breakpoint-large-tablet {
        font-size: 1rem;
      }

      @include breakpoint-laptop {
        font-size: 1.1rem;
      }

      @include breakpoint-large-laptop {
        font-size: 1rem;
      }

      @include breakpoint-desktop {
        font-size: 1rem;
      }

      @include breakpoint-extra-large {
        font-size: 1.2rem;
      }

      &.services-packages-mobile-valeting-page-grid {
        -webkit-line-clamp: unset;
      }
    }
  }

  .services-packages-mobile-valeting-page:nth-child(1){
      grid-column: span 1;
      grid-row: span 1;
  }

  .services-packages-mobile-valeting-page:nth-child(2),
  .services-packages-mobile-valeting-page:nth-child(3){
      grid-column: span 1;
  }

  .services-packages-mobile-valeting-page:nth-child(4),
  .services-packages-mobile-valeting-page:nth-child(5){
      grid-column: span 1;
  }    
  .services-packages-mobile-valeting-page:nth-child(6){
      grid-column: span 1;
      grid-row: span 1;
  }
  .services-packages-mobile-valeting-page:nth-child(7),
  .services-packages-mobile-valeting-page:nth-child(8){
      grid-column: span 1;
  }
  @media screen and (max-width: 650px){
    .container{
        display: block;
    }
  }
}

.contact-button-cont {
  margin: 1rem auto;
  display: grid;
}

.contact-button {
  margin: 15px auto;
  width: 15vw;
  text-align: center;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  border-radius: 20px;
  padding: 15px 20px;
  border: 0.2rem solid #000;

  @include breakpoint-small-mobile {
    width: 50vw;
  }

  @include breakpoint-mobile {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-tablet {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-large-tablet { 
    font-size: 1.4rem;
    width: 30vw;
  }

  @include breakpoint-small-laptop { 
    width: 20vw;
  }

  @include breakpoint-laptop { 
    width: 20vw;
  }

  @include breakpoint-extra-large {
    font-size: 2rem;
  }
}

.contact-button:hover {
  background-color: $main-color-hover;
  color: $secondary-color;
  border: 0.2rem solid $main-color;
}

.brand-colour {
  color: $main-color!important;
  text-shadow: 1px 1px 0px #000000ad;
  font-weight: bold;
}
</style>