<template>
<!-- Header -->
  <header
    class="nav nav--always-fixed"
    id="reduced"
    itemtype="http://schema.org/WPHeader"
    itemscope
  >
    <div class="nav__holder nav--align-center">
      <div class="container-fluid container-semi-fluid">
        <div class="flex-parent">
          <div class="nav__header clearfix">
            <!-- Logo -->
            <div class="logo-wrap local-scroll">
              <router-link to="/" class="nav-link">
                <img
                  class="nav-logo"
                  src="@/img/branding/Apex-Valet-Logo.png"
                  srcset="@/img/branding/Apex-Valet-Logo.png"
                  alt="business logo"
                  itemtype="http://schema.org/Organization"
                  itemscope
                />
              </router-link>
            </div>

            <button
              type="button"
              class="nav__icon-toggle"
              id="nav__icon-toggle"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
            </button>
          </div>
          <!-- end nav-header -->

          <!-- Navbar -->
          <nav
            id="navbar-collapse"
            class="nav__wrap collapse navbar-collapse"
            itemtype="http://schema.org/SiteNavigationElement"
            itemscope="itemscope"
          >

            <ul class="nav__menu local-scroll" id="onepage-nav">
              <!-- About -->
              <li>
                <router-link to="/" class="nav-link">Home</router-link> 
              </li>

              <!-- Services -->
              <li>
                <router-link to="Services" class="nav-link">Services</router-link> 
              </li>

              <!-- Contact us -->
              <li>
                <router-link to="Contact" class="nav-link">Contact</router-link>
              </li> 

              <!-- Mobile Socials -->
                <div class="nav__mobile-socials d-lg-none">
                  <div class="socials">
                    <a
                      href="#"
                      class="social social-facebook"
                      aria-label="facebook"
                      title="facebook"
                      target="_blank"
                    >
                      <i class="ui-facebook"></i>
                    </a>
                    <a
                      href="#"
                      class="social social-linkedin"
                      aria-label="linkedin"
                      title="linkedin"
                      target="_blank"
                    >
                      <i class="ui-linkedin"></i>
                    </a>
                    <a
                      href="#"
                      class="social social-instagram"
                      aria-label="instagram"
                      title="instagram"
                      target="_blank"
                    >
                      <i class="ui-instagram"></i>
                    </a>
                  </div>
              </div>

              
                
            </ul>

          </nav>
          <!-- end nav-wrap -->

          <!-- Socials -->
          <div class="nav__socials flex-child d-none d-lg-block">
            <div class="socials right">

              <a class="social text-center" href="tel:07355569331" target="_blank">
                <img class="social-logo"
                  src="@/img/mobile-logo.png"
                />
              </a>

              <a class="social text-center" href="mailto:info@apexvalet.co.uk" target="_blank">
                <img class="social-logo"
                  src="@/img/mail-logo.png"
                />
              </a>

              <a class="social text-center" href="https://www.facebook.com/profile.php?id=100094321234192" target="_blank">
                <img class="social-logo"
                  src="@/img/facebook-logo.png"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- end flex-parent -->
      </div>
      <!-- end container -->
    </div>
  </header>
  <!-- end navigation -->

</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return{
         activeClass: 'ui-arrow-down'
    }
  },
  methods:{
      toggledroparrow(){
        
        if(this.activeClass == 'ui-arrow-down')
        {
          this.activeClass = 'ui-arrow-up'
        }
        else
        {
          this.activeClass = 'ui-arrow-down'
        }
      }
  },
  mounted: function(){

  }
};
</script>

<style lang="scss">
.social-logo {
  width: 3rem;
}
</style>