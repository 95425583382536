<template>
  <div class="service-hero">    
    <h1 class="service-hero-header">
      SERVICES
    </h1>
  </div>
  <div class="services-page">
    <div class="services-page-container">
      <div class="services-page-text-container">
        <h4>
          All of our staff are extremely experienced in delivering our services to a premium standard.
        </h4>
        <h4>
          All of the products we use on your vehicles are by provided by the leading product provider in the industry AutoSmart.
        </h4>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
.service-hero {
  background-image: linear-gradient(to bottom right, #004a5bf5, #00000000), url("../img/carwash.webp");
  background-position: center;
  background-size: 100vw;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  height: 45rem;
  top: -5vw;

  @include breakpoint-small-mobile {
      height: 35rem;
      top: 0;
    }

    @include breakpoint-mobile{
      height: 35rem;
      top: 0;
    }

    @include breakpoint-tablet{
      height: 25rem;
      top: 0;
    }

    @include breakpoint-large-tablet {
      height: 25rem;
      top: 0vw;
    }

    @include breakpoint-small-laptop{
      height: 35rem;
      top: 0vw;
    }

    @include breakpoint-laptop{
      top: 0vw;
      height: 35rem;
    }  

    @include breakpoint-large-laptop{
      top: 0vw;
      height: 35rem;
    }

    @include breakpoint-desktop{
      top: 0vw;
      height: 35rem;
    }

    @include breakpoint-extra-large {
      top: 0vw;
      height: 35rem;
    }

  img {
    display: block;
    position: relative;
    width: 100vw;
  }
}

.service-hero-header {
  padding: 10vw 5vw;
  font-weight: 400;

  @include breakpoint-small-mobile {
    padding: 15vw 5vw;
  }

  @include breakpoint-mobile{
    padding: 15vw 5vw;
  }

  @include breakpoint-tablet{
    padding: 15vw 5vw;
  }

  @include breakpoint-large-tablet {
    padding: 15vw 5vw;
  }
}

.services-page {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;

  .services-page-container {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    height: auto;

    em {
      color: #f2ba5c;
      font-weight: bold;
      font-style: normal;
    }

    @include breakpoint-mobile {
    }

    @include breakpoint-small-mobile {
    }

    @include breakpoint-tablet {
    }

    .services-page-text-container {
      width: 80vw;
      z-index: 4;
      margin: 4rem auto;
      text-align: center;

      @include breakpoint-small-mobile {
        margin-bottom: 0em;
        margin-left: 0em;
        width: 80vw;
      }

      @include breakpoint-mobile {
        margin-top: 30vh;
        margin-bottom: 0em;
        margin-top: 0vh;
        margin-left: 10vw;
        width: 82vw;
      }

      @include breakpoint-tablet {
        width: 100vw;
        margin-left: 3em;
        height: fit-content;
      }

      @include breakpoint-large-tablet {
        margin-left: 4em;
        width: 55rem;
        p{
          font-size: 1.2rem;          
        }
      }

      @include breakpoint-small-laptop {
        margin-bottom: 0em;
      }

      @include breakpoint-laptop{
        margin-bottom: 0em;
      }

      @include breakpoint-large-laptop{
        margin-bottom: 0em;
      }

      h1,h2,h3,h4,h5,h6 {
        font-weight: 400;
      }

      h1 {
        text-align: center;
        margin: 4rem auto 2rem;
      }

      h4 {
        width: 45vw;
        text-align: center;
        margin: 0rem auto 2rem;
      }

      .h3,h4,h5,h6 {
        font-family: "inter", sans-serif;
      }

      p {
        margin-top: 2rem;
        font-weight: 400;
        width: 40vw;
        margin: 2rem auto;
      }

      em {
        color: $main-color;
        font-weight: 100;
      }
      
      .link-button-container {
        margin-top: 3em;
        margin-bottom: 3em;
      }
    }
  }
}
</style>