<template>
  <div class="service-packages-detailing-detailing-page-cont">      
      <!-- Project Navigation service-packages-detailing-page -->
      <section class="image-text floors">
        <div class="services-page-detailing-text-container">
          <h1 class="services-page-detailing-title text-center">
            Detailing
          </h1>
          <p class="text-center">
            All individual detailing services are quote based. We provide a free vehicle inspection by one of our specialists who will then provide you with a free quote on the back of the inspection.
          </p>
        </div>
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>
            
            <div class="service-packages-detailing-page-container service-packages-detailing-page-grid horizontal">
              <!-- Platinum -->
              <div class="service-packages-detailing-page horizontal">
                <div class="service-packages-detailing-page-inner service-packages-detailing-page-grid horizontal">
                  <div class="service-packages-detailing-page-text service-packages-detailing-page-grid horizontal">
                    <div class="side-list horizontal">
                      <ul class="services-list-grid horizontal">
                        <li>
                          Mopping
                        </li>
                        <li>
                          Hand Wash & Polish
                        </li>                        
                        <li>
                          Window Polish
                        </li>
                        <li>
                          Clay Bar
                        </li>
                        
                      </ul>
                      <ul class="services-list-grid horizontal">
                        <li>
                          Plastic Dressing
                        </li>
                        <li>
                          Engine Clean
                        </li>
                        <li>
                          Exhaust Polish
                        </li>
                        <li>
                          Machine Polish
                        </li>
                      </ul>
                    </div>
                    
                  </div>                    
                </div>
              </div>
            </div>

            <div class="service-packages-detailing-page-container service-packages-detailing-page-grid">

              <!-- Valet -->
              <div class="service-packages-detailing-page small">
                <div class="service-packages-detailing-page-inner service-packages-detailing-page-grid">
                  <div class="service-packages-detailing-page-text service-packages-detailing-page-grid">
                    <h2 class="service-packages-detailing-page-title">Motor Vehicle Valet & Detailing Services</h2>
                    <div class="mt-32">
                      <p class="brand-colour text-centre">
                        Quote basis depending on work required
                      </p>                      
                    </div>
                    
                  </div> 
                </div>
              </div>

              <!-- Commercial Valet & Detailing -->
              <div class="service-packages-detailing-page small">
                <div class="service-packages-detailing-page-inner service-packages-detailing-page-grid">
                  <div class="service-packages-detailing-page-text service-packages-detailing-page-grid">
                    <h2 class="service-packages-detailing-page-title">Commercial Valet & Detailing</h2>
                    <div class="mt-32">
                      <p class="brand-colour text-centre">
                        Quote basis depending on work required
                      </p>                      
                    </div>
                    
                  </div> 
                </div>
              </div>
            </div>
            
            <router-link to="Contact" class="contact-button-cont">
              <div class="contact-button" >Contact Us</div>
            </router-link>

          </div>
        </Grid>
      </section>
    </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
.service-packages-detailing-detailing-page-cont {
  background-image: linear-gradient(to bottom, #060606, #000000a6), url("../img/valeting.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  height: fit-content;
  padding-bottom: 4rem;
}

.services-page-detailing-text-container {
  p {
    font-weight:100;
    width: 60vw;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
  }
}

.services-page-detailing-title {
  margin: 0rem auto 6rem;
  padding-top: 4rem;
  font-weight: 400;
}

.service-packages-detailing-page-container{
  display: grid;
  grid-gap: 1rem;
  min-width: 80vw;
  max-width: 80vw;
  margin: 0 auto;

  &.service-packages-detailing-page-grid {
    grid-template-columns: repeat(2, 1fr);
    min-width: 60vw;
    max-width: 60vw;

    &.horizontal {
      grid-template-columns: repeat(1, 1fr);
      min-width: 30vw;
      width: 30vw;
      max-width: 30vw;

      @include breakpoint-small-mobile {
        min-width: 30vw;
        width: fit-content;
        max-width: 60vw;
      }

      @include breakpoint-mobile{
        min-width: 30vw;
        width: fit-content;
        max-width: 60vw;
      }

      @include breakpoint-tablet{
        min-width: 30vw;
        width: fit-content;
        max-width: 60vw;
      }

      @include breakpoint-large-tablet {
        min-width: 30vw;
        width: fit-content;
        max-width: 60vw;
      }

      @include breakpoint-small-laptop{
        min-width: 30vw;
        width: fit-content;
        max-width: 50vw;
      }

      @include breakpoint-laptop{
        min-width: 30vw;
        width: fit-content;
        max-width: 50vw;
      }  

      @include breakpoint-large-laptop{
        min-width: 30vw;
        width: fit-content;
        max-width: 50vw;
      }
    }
  }

  .side-list {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    margin: 1rem auto 0rem;

    &.horizontal {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 1rem;
      margin: 1rem auto 0rem;
      column-gap: 4rem;
      padding: 0rem 2rem;
    }

    p {
      color: black!important;
      text-align: left;
      font-weight: bold;
      &.brand-colour {
        color: $main-color!important;
        text-shadow: 1px 1px 0px #000000ad;
      }
    }
  }

  

  .service-packages-detailing-page {

    
  }

  .service-packages-detailing-page-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;  
    overflow: hidden;
    height: 15rem;
    width: auto;

    @include breakpoint-small-mobile {
      flex-direction: column;
    }

    @include breakpoint-mobile {
      flex-direction: column;
    }

    @include breakpoint-extra-large {
      width: auto;
    }

    &.service-packages-detailing-page-grid {
      min-height: 25rem;
      max-height: 25rem;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      &.horizontal {
        flex-direction: row;
        min-height: 18rem;
        max-height: 18rem;
      }
    }
  }

  .service-packages-detailing-page-title {
    font-weight: 400;
    color: black!important;
    min-height: 8rem;
    max-height: 8rem;
    height: 8rem;
  }

  .services-list-grid {
    li {
      list-style-type: disc;
      text-align: left;
      color: black;
    }

    &.horizontal {
      li {
        color: white!important;
      }
    }
  }

  .service-packages-detailing-page img{
    width: auto;
    height: -webkit-fill-available;
    object-fit: fill;
  }

  .service-packages-detailing-page-text {
    width: 100%;
    text-align: center;
    padding: 0.7rem;
    background: #ffffffd1;
    color: black!important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-height: 18rem;
    max-height: 18rem;
    border-radius: 35px;
    border: 1rem solid #5394a1;

    &.horizontal {
      min-height: 15rem;
      max-height: 15rem;
      background: unset;
      color: white!important;

      @include breakpoint-small-mobile {
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }

      @include breakpoint-mobile{
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }

      @include breakpoint-tablet{
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }

      @include breakpoint-large-tablet {
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }

      @include breakpoint-small-laptop{
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }

      @include breakpoint-laptop{
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }  

      @include breakpoint-large-laptop{
        min-height: 5rem;
        height: fit-content;
        max-height: 25rem;
      }

      @include breakpoint-desktop{
        height: fit-content;
        min-height: 15rem;
        max-height: 40rem;
      }

      @include breakpoint-extra-large {
        height: fit-content;
        min-height: 15rem;
        max-height: 40rem;
      } 
    }

    &.service-packages-detailing-page-grid {
      justify-content: flex-start;
    }

    h1,h2,h3,h4,h5,h6 {
      font-size: 1.6rem;
    }

    h4,h5,h6 {
      font-size: 1.2rem;
    }

    h1,h2,h3,h4,h5,h6,p,ul {
      margin: 0.1rem 0rem!important;
      color: $main-color-hover;

      @include breakpoint-large-tablet {
        font-size: 1.4rem;
      }
    }

    p {
      display: -webkit-box;
      font-size: 0.8rem;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      @include breakpoint-large-tablet {
        font-size: 1rem;
      }

      @include breakpoint-laptop {
        font-size: 1.1rem;
      }

      @include breakpoint-large-laptop {
        font-size: 1rem;
      }

      @include breakpoint-desktop {
        font-size: 1rem;
      }

      @include breakpoint-extra-large {
        font-size: 1.2rem;
      }

      &.service-packages-detailing-page-grid {
        -webkit-line-clamp: unset;
      }
    }
  }

  .service-packages-detailing-page:nth-child(1){
      grid-column: span 1;
      grid-row: span 1;
  }

  .service-packages-detailing-page:nth-child(2),
  .service-packages-detailing-page:nth-child(3){
      grid-column: span 1;
  }

  .service-packages-detailing-page:nth-child(4),
  .service-packages-detailing-page:nth-child(5){
      grid-column: span 1;
  }    
  .service-packages-detailing-page:nth-child(6){
      grid-column: span 1;
      grid-row: span 1;
  }
  .service-packages-detailing-page:nth-child(7),
  .service-packages-detailing-page:nth-child(8){
      grid-column: span 1;
  }
  @media screen and (max-width: 650px){
    .container{
        display: block;
    }
  }
}

.contact-button-cont {
  margin: 1rem auto;
  display: grid;
}

.contact-button {
  margin: 15px auto;
  width: 15vw;
  text-align: center;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  border-radius: 20px;
  padding: 15px 20px;
  border: 0.2rem solid #000;

  @include breakpoint-small-mobile {
    width: 50vw;
  }

  @include breakpoint-mobile {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-tablet {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-large-tablet { 
    font-size: 1.4rem;
    width: 30vw;
  }

  @include breakpoint-small-laptop { 
    width: 20vw;
  }

  @include breakpoint-laptop { 
    width: 20vw;
  }

  @include breakpoint-extra-large {
    font-size: 2rem;
  }
}

.contact-button:hover {
  background-color: $main-color-hover;
  color: $secondary-color;
  border: 0.2rem solid $main-color;
}

.brand-colour {
  color: $main-color!important;
  text-shadow: 1px 1px 0px #000000ad;
  font-weight: bold;
}
</style>