<template>
  <div class="welcome">    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
  .welcome {
    background-image: linear-gradient(to bottom right, #004a5bf5, #00000000), url("../img/Contact.jpg");
    background-position: center;
    background-size: 175vw;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    height: 35rem;
    top: -5vw;

    @include breakpoint-small-mobile {
      height: 35rem;
      top: 0;
    }

    @include breakpoint-mobile{
      height: 35rem;
      top: 0;
    }

    @include breakpoint-tablet{
      height: 35rem;
      top: 0;
    }

    @include breakpoint-large-tablet {
      height: 35rem;
      top: 0vw;
    }

    @include breakpoint-small-laptop{
      height: 35rem;
      top: 0vw;
    }

    @include breakpoint-laptop{
      top: 0vw;
      height: 35rem;
    }  

    @include breakpoint-large-laptop{
      top: 0vw;
      height: 35rem;
    }

    @include breakpoint-desktop{
      top: 0vw;
      height: 35rem;
    }

    @include breakpoint-extra-large {
      top: 0vw;
      height: 35rem;
    }

    img {
      display: block;
      position: relative;
      width: 100vw;
    }
  }
</style>