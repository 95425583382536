<template>
  <div class="contact-hero">
  </div>
  <div class="contact-page">
    <div class="contact-page-container">
      <div class="contact-page-text-container">
        <h1>
          APEX VALET
        </h1>
        <h4>
          Our premises is based in Market Drayton’s, Tern Valley Business Park. 
        </h4>
        <h4>
          For any questions, queries, booking your on site valet, mobile valet, detailing service, please contact us on the below details.
        </h4>
      </div>
    </div>
  </div>

  <div class="contact-logo-cont">
    <a class="text-center" href="tel:07355569331" target="_blank">
      <img class="contact-logo"
        src="@/img/mobile-logo.png"
      />
    </a>

    <a class="text-center" href="mailto:info@apexvalet.co.uk" target="_blank">
      <img class="contact-logo"
        src="@/img/mail-logo.png"
      />
    </a>

    <a class="text-center" href="https://www.facebook.com/profile.php?id=100094321234192" target="_blank">
      <img class="contact-logo"
        src="@/img/facebook-logo.png"
      />
    </a>
  </div>
  <Grid>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2406.834165834719!2d-2.5154844!3d52.8974093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a892f6b3eb8c7%3A0x9eb0e1af6a439b75!2sTern%20Valley%20Business%20Park%2C%20Market%20Drayton%20TF9%203SQ!5e0!3m2!1sen!2suk!4v1694205669903!5m2!1sen!2suk" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" aria-hidden="false" tabindex="0">>
    </iframe>
  </Grid>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";

.contact-logo-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  column-gap: 2rem;
  margin: 2rem auto;

}

.contact-logo {
  width: 14rem;
}

.contact-logo:hover {
  border: solid 5px #fff;
  background-color: #eed;
  box-shadow: 10px -10px rgba(0,0,0,0.6);
  -moz-box-shadow: 10px -10px rgba(0,0,0,0.6);
  -webkit-box-shadow: 1px -1px 0px 0px rgba(0,0,0,0.6);
  -o-box-shadow: 10px -10px rgba(0,0,0,0.6);
  border-radius: 124px;
}


.contact-hero {
  background-image: linear-gradient(to bottom right, #004a5bf5, #00000000), url("../img/contact-hero.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  height: 45rem;
  top: -5vw;

  @include breakpoint-small-mobile {
      height: 35rem;
      top: 0;
    }

    @include breakpoint-mobile{
      height: 35rem;
      top: 0;
    }

    @include breakpoint-tablet{
      height: 25rem;
      top: 0;
    }

    @include breakpoint-large-tablet {
      height: 25rem;
      top: 0vw;
    }

    @include breakpoint-small-laptop{
      height: 35rem;
      top: 0vw;
    }

    @include breakpoint-laptop{
      top: 0vw;
      height: 35rem;
    }  

    @include breakpoint-large-laptop{
      top: 0vw;
      height: 35rem;
    }

    @include breakpoint-desktop{
      top: 0vw;
      height: 35rem;
    }

    @include breakpoint-extra-large {
      top: 0vw;
      height: 35rem;
    }

  img {
    display: block;
    position: relative;
    width: 100vw;
  }
}

.contact-hero-header {
  padding: 10vw 5vw;
  font-weight: 400;
}

.contact-page {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;

  .contact-page-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: auto;

    em {
      color: #f2ba5c;
      font-weight: bold;
      font-style: normal;
    }

    @include breakpoint-mobile {
    }

    @include breakpoint-small-mobile {
    }

    @include breakpoint-tablet {
    }

    .contact-page-text-container {
      width: 80vw;
      z-index: 4;
      margin: 4rem auto;
      text-align: center;

      @include breakpoint-small-mobile {
        margin-bottom: 0em;
        width: 80vw;
      }

      @include breakpoint-mobile {
        margin-top: 30vh;
        margin-bottom: 0em;
        margin-top: 0vh;
        margin-left: 10vw;
        width: 82vw;
      }

      @include breakpoint-tablet {
        width: 100vw;
        height: fit-content;
      }

      @include breakpoint-large-tablet {
        width: 55rem;
        p{
          font-size: 1.2rem;          
        }
      }

      @include breakpoint-small-laptop {
        margin-bottom: 0em;
      }

      @include breakpoint-laptop{
        margin-bottom: 0em;
      }

      @include breakpoint-large-laptop{
        margin-bottom: 0em;
      }

      h1,h2,h3,h4,h5,h6 {
        font-weight: 400;
      }

      h1 {
        text-align: center;
        margin: 4rem auto 2rem;
      }

      h4 {
        width: 45vw;
        text-align: center;
        margin: 0rem auto 2rem;
      }

      .h3,h4,h5,h6 {
        font-family: "inter", sans-serif;
      }

      p {
        margin-top: 2rem;
        font-weight: 400;
        width: 40vw;
        margin: 2rem auto;
      }

      em {
        color: $main-color;
        font-weight: 100;
      }
      
      .link-button-container {
        margin-top: 3em;
        margin-bottom: 3em;
      }
    }
  }
}
</style>