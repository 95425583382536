@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="WhatWeDo"
    itemscope
    itemtype="http://schema.org/WebPage"
    ><main class="main-wrap">
      <div class="content-wrap">
        <ServicesHero/>
        <ServicesPackagesValeting/>
        <ServicesPackagesDetailing/>  
        <ServicesPackagesMobileValeting/> 


      </div>
    </main>
  </body>
</template>

<script>
import ServicesHero from '@/components/ServicesHero.vue';
import ServicesPackagesValeting from '@/components/ServicesPackagesValeting.vue';
import ServicesPackagesDetailing from '@/components/ServicesPackagesDetailing.vue';
import ServicesPackagesMobileValeting from '@/components/ServicesPackagesMobileValeting.vue';


export default {
  components: {
    ServicesHero,
    ServicesPackagesValeting,
    ServicesPackagesDetailing,
    ServicesPackagesMobileValeting,
    
  }
} 
</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $quarteriary-color: #eea424;

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }
</style>