<template>  
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="contact"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <main class="main-wrap">
      <div class="content-wrap">
        <ContactHero/>
        <OpeningTimes/>
        <ContactForm />
      </div>
    </main>
  </body>
</template>

<script>
import ContactHero from '@/components/ContactHero.vue';
import OpeningTimes from '@/components/OpeningTimes.vue';
import ContactForm from "@/components/ContactForm.vue";

ContactHero.vue

export default{  
 name: "Contact",
  components: {
    ContactForm,
    OpeningTimes,
    ContactHero,
  },
  metaInfo() {
    return { 
    }
  }, 
  data() {
    return {
    };
  },
  methods: {
  },  
};

</script>

<style lang="scss">
</style>