<template>
  <!-- Could make this nav a component later -->
<div id="app">
  <Navigation/>
  <router-view/>
  <Footer />
</div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'Apex Valet Website',
  components: {
   Navigation,
   Footer,
  },
   data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
}

</script>

<style lang="scss">

@import "@/styles/style.scss";

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   margin-top: 60px;
// }

// /* Should this be in a css file that we import */
// h1{
//   border-bottom: 1px solid #ddd;
//   display: inline-block;
//   padding-bottom: 10px;
// }
</style>
