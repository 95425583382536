@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="Privacy"
    itemscope
    itemtype="http://schema.org/WebPage"
    >   
    <main class="main-wrap">
      <div class="content-wrap">

        
      </div>
    </main>
  </body>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';

.privacy-container {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6vw;    
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

.privacy-policy {
  margin-top: 10rem;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 25px;
  color: black;
  background-image: linear-gradient(263deg, #fc622a, #f8eb70);
  padding: 2em;
}

.privacy-policy-title {
  margin-top: 2vw;
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  text-align: center;    
}

.privacy-policy-sub-title {
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  margin-top: 2vw;

  @include breakpoint-mobile {
    font-size: 5vw;
    width: auto;
    margin-top: 5vw;
  }
}

.privacy-policy-body-text {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  word-break: break-word;

  @include breakpoint-mobile {
    width: auto;
  }

  a {
    color: #162431 !important;
    cursor: pointer;
    font-weight: bold;
    text-shadow: 2px 2px 3px #ffffff;
  }
}

  
</style>