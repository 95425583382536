<template>
  <section id="services" class="home">
    <h2 class="portfolio-header">FULLY INSURED</h2>
    <div class="container">
      <div class="row">
        <div class="tile-container vertical-tile-grid">
          <!-- New tile article goes here -->
          <div class="tile">
            <div class="tile-inner vertical-tile-grid">                
              
              <div class="tile-text vertical-tile-grid">
                <h2 class="headline-text">Car wash & Valeting</h2>              
              </div>
              <div class="tile-img-cont">
                <img src="@/img/car-wash.jpg" alt=""> 
              </div>              
            </div>
          </div>

          <div class="tile">
            <div class="tile-inner vertical-tile-grid">              
              <div class="tile-text vertical-tile-grid">
                <h2 class="headline-text">Detailing</h2>
              </div>
              <div class="tile-img-cont">
                <img src="@/img/valeting.jpg" alt="">
              </div>
            </div>
          </div>              
        </div>      
      </div>  
    </div>
    <router-link to="Services" class="portfolio-button-cont">
      <div class="portfolio-button" >Find out more</div>
    </router-link>
  </section>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
section {
  background-color: rgba(22, 36, 49, 0);
  padding: 0rem 4em;
  position: relative;
  background-size: cover;
  background-position: center;
}

.container {
  width: 90%;
  height: auto;
  padding: unset;
  margin: 20px auto;
}

.portfolio-header {
  text-align: center;
  font-weight: 400;
}

.portfolio-button-cont {
  margin: 1rem auto;
  display: grid;
}

.portfolio-button {
  margin: 15px auto;
  width: 15vw;
  text-align: center;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  border-radius: 20px;
  padding: 15px 20px;
  border: 0.2rem solid #000;

  @include breakpoint-small-mobile {
    width: 50vw;
  }

  @include breakpoint-mobile {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-tablet {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-large-tablet { 
    font-size: 1.4rem;
    width: 30vw;
  }

  @include breakpoint-small-laptop { 
    width: 20vw;
  }

  @include breakpoint-laptop { 
    width: 20vw;
  }

  @include breakpoint-extra-large {
    font-size: 2rem;
  }
}

.portfolio-button:hover {
  background-color: $main-color-hover;
  color: $secondary-color;
  border: 0.2rem solid $main-color;
}

.tile-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  min-width: 80vw;
  max-width: 80vw;
  margin: 0 auto;

  &.vertical-tile-grid {
    grid-template-columns: repeat(2, 1fr);
    min-width: 70vw;
    max-width: 90vw;
  }

  .tile-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;  
    overflow: hidden;    
    cursor: pointer;
    height: 15rem;
    width: auto;

    @include breakpoint-small-mobile {
      flex-direction: column;
    }

    @include breakpoint-mobile {
      flex-direction: column;
    }

    @include breakpoint-extra-large {
      width: auto;
    }

    &.vertical-tile-grid {
      min-height: 30rem;
      height: fit-content;
      max-height: 35rem;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .tile-img-cont {
    min-width: 20rem;
    width: 20rem;
    max-width: 20rem;
    min-height: 20rem;
    height: 20rem;
    max-height: 20rem;
    overflow: hidden;
    border-radius: 15px;
  }

  .tile img{
    min-width: 40rem;
    width: 40rem;
    max-width: 40rem;
    height: -webkit-fill-available;
    object-fit: fill;
  }

  .tile-text {
    width: 100%;
    text-align: center;
    padding: 0.7rem;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    height: fit-content;

    &.vertical-tile-grid {
      justify-content: flex-start;
      min-height: 2rem;
    }

    h1,h2,h3,h4,h5,h6 {
      font-size: 1.6rem;
      
    }

    h4,h5,h6 {
      font-size: 1.2rem;
    }

    h1,h2,h3,h4,h5,h6,p,ul {
      margin: 0rem 0rem 2rem 0rem !important;
      color: $main-color-hover;

      @include breakpoint-large-tablet {
        font-size: 1.4rem;
      }
    }

    p {
      display: -webkit-box;
      font-size: 0.8rem;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      @include breakpoint-large-tablet {
        font-size: 1rem;
      }

      @include breakpoint-laptop {
        font-size: 1.1rem;
      }

      @include breakpoint-large-laptop {
        font-size: 1rem;
      }

      @include breakpoint-desktop {
        font-size: 1rem;
      }

      @include breakpoint-extra-large {
        font-size: 1.2rem;
      }

      &.vertical-tile-grid {
        -webkit-line-clamp: unset;
      }
    }
  }

  .tile:nth-child(1){
      grid-column: span 1;
      grid-row: span 1;
  }

  .tile:nth-child(2),
  .tile:nth-child(3){
      grid-column: span 1;
  }

  .tile:nth-child(4),
  .tile:nth-child(5){
      grid-column: span 1;
  }    
  .tile:nth-child(6){
      grid-column: span 1;
      grid-row: span 1;
  }
  .tile:nth-child(7),
  .tile:nth-child(8){
      grid-column: span 1;
  }
  @media screen and (max-width: 650px){
    .container{
        display: block;
    }
  }
}

</style>