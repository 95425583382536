<template>
  <div class="image-splash">    
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
  .image-splash {
    background-image: linear-gradient(to top right, #0092b3f5, #00000000), url("../img/carshining.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    height: 70vh;
    margin-top: 5rem;

    @include breakpoint-small-mobile {
      height: 20vh;
    }

    @include breakpoint-mobile{
      height: 20vh;
    }

    @include breakpoint-tablet{
      height: 30vh;
    }

    @include breakpoint-large-tablet {
      height: 50vh;
    }

    @include breakpoint-small-laptop{
      height: 70vh;
    }

    @include breakpoint-laptop{
      height: 70vh;
    }  

    @include breakpoint-large-laptop{
      height: 70vh;
    }

    @include breakpoint-desktop{
      height: 70vh;
    }

    @include breakpoint-extra-large {
      height: 70vh;
    }

    img {
      display: block;
      position: relative;
      width: 100vw;
    }
  }

</style>