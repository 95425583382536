<template>
  <div class="introduction">
    <div class="introduction-container">
      <div class="introduction-text-container">
        <h1>
          VALET & DETAILING SPECIALIST
        </h1>
        <h2>
          <em>"Time to Shine"</em>
        </h2>
        <p>
          Apex Valet provide the highest quality Hand Car Wash, Vehicle Valeting and Detailing services for both private and business vehicle owners. 
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
.introduction {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;

  .introduction-container {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    height: auto;

    em {
      color: #f2ba5c;
      font-weight: bold;
      font-style: normal;
    }

    @include breakpoint-mobile {
    }

    @include breakpoint-small-mobile {
    }

    @include breakpoint-tablet {
    }

    .introduction-text-container {
      width: 80vw;
      z-index: 4;
      margin: 4rem auto;
      text-align: center;

      @include breakpoint-small-mobile {
        margin-bottom: 0em;
        margin-left: 0em;
        width: 80vw;
      }

      @include breakpoint-mobile {
        margin-top: 30vh;
        margin-bottom: 0em;
        margin-top: 0vh;
        margin-left: 10vw;
        width: 82vw;
      }

      @include breakpoint-tablet {
        width: 100vw;
        margin-left: 3em;
        height: fit-content;
      }

      @include breakpoint-large-tablet {
        margin-left: 4em;
        width: 55rem;
        p{
          font-size: 1.2rem;          
        }
      }

      @include breakpoint-small-laptop {
        margin-bottom: 0em;
      }

      @include breakpoint-laptop{
        margin-bottom: 0em;
      }

      @include breakpoint-large-laptop{
        margin-bottom: 0em;
      }

      h1,h2,h3,h4,h5,h6 {
        font-weight: 400;
      }

      p {
        margin-top: 2rem;
        font-weight: 400;
        width: 40vw;
        margin: 2rem auto;
      }

      em {
        color: $main-color;
        font-weight: 100;
      }
      
      .link-button-container {
        margin-top: 3em;
        margin-bottom: 3em;
      }
    }
  }
}
</style>